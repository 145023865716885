import { Component } from '@angular/core';
import { APP_ROUTE, DESK_ROUTE, EXCHANGE_ROUTE, HELP_ROUTE, MODERATION_ROUTE, SETTINGS_ROUTE, USERS_ROUTE } from '../../../core/routes';
import { RolesService } from '../../../core/services/roles.service';
import { slideToggleAnimation } from '../../../ui/animations';
import { MenuItem } from '../../../core/interface';
import { LayoutService } from '../../layout.service';
import { GlobicaService } from '../../../core/services/globica.service';

@Component({
  selector: 'adxad-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [slideToggleAnimation]
})
export class MenuComponent {
  isNavBarOpen = false;

  public menuConfig: MenuItem[] = [
    {
      id: 'tradeDesk',
      name: 'menu_tradeDesk',
      route: APP_ROUTE.tradeDesk,
      icon: 'bar_chart',
      hide: this.roles.isReporters(),
      isOpenSubMenu: this.isOpenMenuItem('tradeDesk'),
      translated: true,
      globicaKey: 'linkMenuTradeDesk_click',
      items: [
        {
          name: 'menu_campaigns',
          route: APP_ROUTE.tradeDesk + '/' + DESK_ROUTE.campaigns,
          translated: true,
          globicaKey: 'linkMenuCampaigns_click'
        },
        {
          name: 'menu_creatives',
          route: APP_ROUTE.tradeDesk + '/' + DESK_ROUTE.creatives,
          translated: true,
          globicaKey: 'linkMenuCreatives_click'
        },
        {
          name: 'menu_projects',
          route: APP_ROUTE.tradeDesk + '/' + DESK_ROUTE.projects,
          translated: true,
          globicaKey: 'linkMenuProjects_click'
        }
      ]
    },
    {
      id: 'reports',
      name: 'menu_reports',
      route: APP_ROUTE.reports,
      icon: 'analytics',
      translated: true,
      globicaKey: 'linkMenuReports_click'
    },
    {
      id: 'flat',
      name: 'Flat deal',
      route: APP_ROUTE.flat,
      icon: 'wallpaper',
      badge: 'favorite',
      hide: !this.roles.isManagers(),
    },
    {
      id: 'moderation',
      name: 'Moderation',
      route: APP_ROUTE.moderation,
      icon: 'add_photo_alternate',
      hide: !this.roles.isManagers(),
      isOpenSubMenu: this.isOpenMenuItem('moderation'),
      items: [
        {
          name: 'Pending',
          route: APP_ROUTE.moderation + '/' + MODERATION_ROUTE.pending
        },
        {
          name: 'Checking',
          route: APP_ROUTE.moderation + '/' + MODERATION_ROUTE.checking
        },
        {
          name: 'Approved',
          route: APP_ROUTE.moderation + '/' + MODERATION_ROUTE.approved
        },
        {
          name: 'Rejected',
          route: APP_ROUTE.moderation + '/' + MODERATION_ROUTE.rejected
        },
        {
          name: 'Need info',
          route: APP_ROUTE.moderation + '/' + MODERATION_ROUTE.needInfo
        }
      ]
    },
    {
      id: 'payments',
      name: 'menu_payments',
      route: APP_ROUTE.payments,
      icon: 'credit_card',
      translated: true,
      globicaKey: 'linkMenuPayments_click'
    },
    {
      id: 'users',
      name: 'Users',
      route: APP_ROUTE.users,
      icon: 'people',
      hide: !this.roles.isManagers(),
      isOpenSubMenu: this.isOpenMenuItem('users'),
      items: [
        {
          name: 'Users',
          route: APP_ROUTE.users + '/' + USERS_ROUTE.list
        },
        {
          name: 'Teams',
          route: APP_ROUTE.users + '/' + USERS_ROUTE.teams
        }
      ]
    },
    {
      id: 'settings',
      name: 'Settings',
      route: APP_ROUTE.settings + '/' + SETTINGS_ROUTE.ssps,
      icon: 'settings',
      isOpenSubMenu: this.isOpenMenuItem('settings'),
      hide: !this.roles.isManagers(),
      items: [
        {
          name: "SSP's",
          hide: !this.roles.isAdmins(),
          route: APP_ROUTE.settings + '/' + SETTINGS_ROUTE.ssps
        },
        {
          name: 'Segments',
          hide: !this.roles.isSuperAdmin(),
          route: APP_ROUTE.settings + '/' + SETTINGS_ROUTE.segments
        },
        {
          name: 'Events log',
          hide: !this.roles.isAdmins(),
          route: APP_ROUTE.settings + '/' + SETTINGS_ROUTE.events
        },
        {
          name: 'Translations',
          route: APP_ROUTE.settings + '/' + SETTINGS_ROUTE.translations
        },
        {
          name: 'Black list sites',
          hide: !this.roles.isSuperAdmin(),
          route: APP_ROUTE.settings + '/' + SETTINGS_ROUTE.spots
        },
        {
          name: 'Token tool',
          hide: !this.roles.isAdmins(),
          route: APP_ROUTE.settings + '/' + SETTINGS_ROUTE.tokenTool
        }
      ]
    },
    {
      id: 'exchange',
      name: 'Exchange',
      route: APP_ROUTE.exchange,
      icon: 'change_circle',
      translated: false,
      globicaKey: 'linkMenuExchange_click',
      hide: !this.roles.isAdmins(),
      isOpenSubMenu: this.isOpenMenuItem('exchange'),
      items: [
        {
          name: "SSP's",
          route: APP_ROUTE.exchange + '/' + EXCHANGE_ROUTE.ssps
        },
        {
          name: "DSP's",
          route: APP_ROUTE.exchange + '/' + EXCHANGE_ROUTE.dsps
        },
        {
          name: 'Report',
          route: APP_ROUTE.exchange + '/' + EXCHANGE_ROUTE.simpleReport
        },
        {
          name: 'Quality report',
          route: APP_ROUTE.exchange + '/' + EXCHANGE_ROUTE.qualityReport
        },
        {
          name: 'Compact quality report',
          route: APP_ROUTE.exchange + '/' + EXCHANGE_ROUTE.compactQualityReport
        }
      ]
    },
    {
      id: 'help',
      name: 'menu_help',
      route: APP_ROUTE.help + '/' + HELP_ROUTE.tradingDeskHelp,
      icon: 'help_outline',
      hide: this.roles.isReporters(),
      isOpenSubMenu: this.isOpenMenuItem('help'),
      translated: true,
      globicaKey: 'linkMenuHelp_click',
      items: [
        {
          name: 'menu_tradeDeskHelp',
          route: APP_ROUTE.help + '/' + HELP_ROUTE.tradingDeskHelp,
          translated: true,
          globicaKey: 'linkMenuTadingDeskHelp_click'
        },
        {
          name: 'menu_forAdvertisersHelp',
          route: APP_ROUTE.help + '/' + HELP_ROUTE.forAdvertisersHelp,
          translated: true,
          globicaKey: 'linkMenuForAdvertisersHelp_click'
        },
        {
          name: 'menu_conversionsImportService',
          route: APP_ROUTE.help + '/' + HELP_ROUTE.conversionsImportService,
          translated: true,
          globicaKey: 'linkMenuConversionsImportService_click'
        }
      ]
    },
    {
      id: 'api',
      name: 'menu_ApiDocumentation',
      route: APP_ROUTE.api,
      icon: 'schema',
      translated: true,
      globicaKey: 'linkMenuApiDocumentation_click'
    }
  ];

  constructor(
    private roles: RolesService,
    public layoutService: LayoutService,
    public globica: GlobicaService
  ) {}

  /**
   * Load from local storage is open submenu falg
   * @param {string} id of menu item
   */
  isOpenMenuItem(id: string): boolean {
    return localStorage.getItem('isOpenMenuItem:' + id) === 'true';
  }

  /**
   * Open menu
   */
  openMenu(): void {
    this.isNavBarOpen = true;
  }

  /**
   * Close menu
   */
  closeMenu(): void {
    if (this.layoutService.isMenuStaticOpened) {
      return;
    }

    this.isNavBarOpen = false;
  }

  /**
   * Show/hide sub menu
   * @param e
   * @param item
   */
  toggleSubMenu(e: MouseEvent, item: MenuItem): void {
    e.stopPropagation();
    e.preventDefault();

    item.isOpenSubMenu = !item.isOpenSubMenu;
    localStorage.setItem('isOpenMenuItem:' + item.id, item.isOpenSubMenu.toString());
  }
}
